<template>
  <div v-show="isShow">
    <el-form
      ref="reconciliationform"
      :model="reconciliationform"
      :label-width="reconciliationform.Accounttype == 3 ? '150px' : '120px'"
      :rules="reconciliationrules"
    >
      <el-form-item label="账户类型：" prop="Accounttype" required>
        <div v-if="isSave">
          {{
            reconciliationform.Accounttype == 1
              ? '对公账户'
              : reconciliationform.Accounttype == 2
              ? '对私账户'
              : '微信'
          }}
        </div>
        <el-radio-group v-model="reconciliationform.Accounttype" @change="clearFormError" v-else>
          <el-radio :label="1">对公账户</el-radio>
          <el-radio :label="2">对私账户</el-radio>
          <el-radio :label="3">微信</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="reconciliationform.Accounttype == 1 || reconciliationform.Accounttype == 2"
        label="银行信息："
        prop="Bankinfo"
      >
        <div v-if="isSave">{{ reconciliationform.Bankinfo }}</div>
        <el-input v-model="reconciliationform.Bankinfo" class="nomalwidth" v-else></el-input>
      </el-form-item>
      <template v-if="reconciliationform.Accounttype === 1">
        <el-form-item label="公司账户：" prop="Companyaccount">
          <div v-if="isSave">{{ reconciliationform.Companyaccount }}</div>
          <el-input
            v-model="reconciliationform.Companyaccount"
            class="nomalwidth"
            v-else
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称：" prop="Companyname">
          <div v-if="isSave">{{ reconciliationform.Companyname }}</div>
          <el-input v-model="reconciliationform.Companyname" class="nomalwidth" v-else></el-input>
        </el-form-item>
      </template>
      <template v-if="reconciliationform.Accounttype === 2">
        <el-form-item label="银行卡号：" prop="Bankcardno">
          <div v-if="isSave">{{ reconciliationform.Bankcardno }}</div>
          <el-input v-model="reconciliationform.Bankcardno" class="nomalwidth" v-else></el-input>
        </el-form-item>
        <el-form-item label="持卡人姓名：" prop="Cardname">
          <div v-if="isSave">{{ reconciliationform.Cardname }}</div>
          <el-input v-model="reconciliationform.Cardname" class="nomalwidth" v-else></el-input>
        </el-form-item>
      </template>
      <template v-if="reconciliationform.Accounttype === 3">
        <el-form-item label="微信到账用户：" label-width="150px">
          <div v-if="isSave">{{ storeinfo.openid }}</div>
          <el-input
            v-if="!isSave"
            style="width: 300px"
            v-model="wxMan.nickname"
            disabled
          ></el-input>
          <el-button v-if="!isSave" type="primary" @click="chooseWx">选择会员</el-button>
        </el-form-item>
        <el-form-item v-if="wxMan.avatar != '' && !isSave" label="" label-width="150px">
          <img :src="wxMan.avatar" alt="" />
        </el-form-item>
      </template>
      <!-- <el-form-item style="margin-top: 240px">
        <el-button type="primary" @click="onSaveOrEdit" size="small" style="width: 80px">{{ isSave ? '编辑' : '保存' }}</el-button>
      </el-form-item> -->
      <Preservation @preservation="onSaveOrEdit" :isSave="isSave"></Preservation>
    </el-form>
    <el-dialog title="选择会员" :visible.sync="dialogVisible" width="52%">
      <ListComponents v-if="dialogVisible" :styleMethon="5" @getListItem="getItem"></ListComponents>
    </el-dialog>
  </div>
</template>

<script>
import ListComponents from '@/components/listComponents'
import Preservation from '@/components/preservation'
export default {
  components: {
    ListComponents,
    Preservation
  },
  props: {
    storeinfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      dialogVisible: false,
      wxMan: {
        nickname: '',
        avatar: '',
        openid: null
      },
      page: 1,
      rows: 10,
      total: 0,
      reconciliationform: {
        Accounttype: 1,
        Bankinfo: null,
        Companyaccount: null,
        Companyname: null,
        Bankcardno: null,
        Cardname: null
      },
      reconciliationrules: {
        Bankinfo: [{ required: true, message: '请输入银行信息', trigger: 'blur' }],
        Companyaccount: [{ required: true, message: '请输入公司账户', trigger: 'blur' }],
        Companyname: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        Bankcardno: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
        Cardname: [{ required: true, message: '请输入持卡人姓名', trigger: 'blur' }]
      },
      isSave: false,
      isShow: false
    }
  },
  methods: {
    chooseWx() {
      this.dialogVisible = true
    },
    onSaveOrEdit() {
      if (!this.isSave) {
        console.log(1)
        // this.$refs.reconciliationform.validate((valid) => {
        //   console.log(valid)
        //   if (valid) {
        let reconciliationform = this.reconciliationform
        let self = this
        this.$axios
          .post(this.$api.store.editAccount, {
            id: self.storeinfo.id,
            type: reconciliationform.Accounttype,
            bank:
              reconciliationform.Accounttype == 1 || reconciliationform.Accounttype == 2
                ? reconciliationform.Bankinfo
                : null,
            bank_card:
              reconciliationform.Accounttype === 1
                ? reconciliationform.Companyaccount
                : reconciliationform.Accounttype == 2
                ? reconciliationform.Bankcardno
                : null,
            bank_name:
              reconciliationform.Accounttype === 1
                ? reconciliationform.Companyname
                : reconciliationform.Accounttype == 2
                ? reconciliationform.Cardname
                : null,
            openid: reconciliationform.Accounttype == 3 ? self.wxMan.mini_openid : null
          })
          .then((res) => {
            if (res.code == 0) {
              this.isSave = true
              this.getInfo()
            } else {
              this.$message.error(res.msg)
            }
          })
        //   }
        // })
      } else {
        this.isSave = false
      }
    },
    clearFormError() {
      if (!this.isSave) {
        this.$refs.reconciliationform.clearValidate()
      }
    },
    getInfo() {
      this.isShow = false
      this.$axios
        .post(this.$api.store.storeList, {
          store_name: this.storeinfo.store_name,
          store_phone: null,
          is_open: null,
          start_time: null,
          end_time: null
        })
        .then((res) => {
          if (res.code == 0) {
            let obj = res.result.list.filter((item) => item.id === this.storeinfo.id)[0]
            this.storeinfo = obj
            this.isShow = true
            console.log(obj)
            if (obj.bank) {
              this.isSave = true
              this.reconciliationform = {
                Accounttype: obj.type,
                Bankinfo: obj.bank,
                Companyaccount: obj.type === 1 ? obj.bank_card : null,
                Companyname: obj.type === 1 ? obj.bank_name : null,
                Bankcardno: obj.type === 2 ? obj.bank_card : null,
                Cardname: obj.type === 2 ? obj.bank_name : null
              }
            }
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    getItem(item) {
      this.wxMan = item
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.nomalwidth {
  width: 780px;
}
</style>
