<template>
  <el-main>
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="商品管理" name="goodsManagement">
        <GoodsManagement class="tabitem" ref="GoodsManagement"></GoodsManagement>
      </el-tab-pane>
      <el-tab-pane label="商户账号管理" name="userSet">
        <UserSet :id="id"></UserSet>
      </el-tab-pane>
      <!-- 店员账号管理 -->
      <!-- <el-tab-pane label="店员账号管理" name="clerkAccountManagemen" >
          <ClerkAccountManagemen class="tabitem" v-if="storeinfo" :storeinfo="storeinfo" ref="ClerkAccountManagemen" ></ClerkAccountManagemen>
        </el-tab-pane> -->
      <el-tab-pane label="对账管理" name="reconciliationManagemen">
        <ReconciliationManagemen class="tabitem" ref="ReconciliationManageme" :storeinfo="storeinfo" v-if="storeinfo"></ReconciliationManagemen>
      </el-tab-pane>
      <el-tab-pane label="合伙人设置" name="headSetting">
        <HeadSetting class="tabitem" ref="headSetting" :store_id="id"></HeadSetting>
      </el-tab-pane>
    </el-tabs>
  </el-main>
</template>

<script>
import GoodsManagement from './components/GoodsManagement';
import ClerkAccountManagemen from './components/ClerkAccountManagemen';
import ReconciliationManagemen from './components/ReconciliationManagemen';
import UserSet from './components/userSet';
import HeadSetting from './components/headSetting';

export default {
  components: {
    GoodsManagement,
    // ClerkAccountManagemen,
    ReconciliationManagemen,
    UserSet,
    HeadSetting,
  },
  data: function () {
    return {
      activeName: 'goodsManagement',
      storeinfo: null,
      id: 0,
    };
  },
  methods: {
    handleTabClick(val) {
      if (val.name === 'goodsManagement') {
        this.initGoodsManagement();
      } else if (val.name === 'clerkAccountManagemen') {
        this.initClerkAccountManagemen();
      } else if (val.name === 'reconciliationManagemen') {
        this.initReconciliationManagemen();
      } else if (val.name === 'headSetting') {
        this.initHeadSetting();
      }
      this.$refs.ReconciliationManageme.clearFormError();
    },
    getStoreInfo(info) {
      this.$axios
        .post(this.$api.store.storeList, {
          store_id: info.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.storeinfo = res.result.list[0];
            console.log(this.storeinfo);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    initClerkAccountManagemen() {
      this.$refs.ClerkAccountManagemen.getClerkList();
    },
    initReconciliationManagemen() {
      this.$refs.ReconciliationManageme.getInfo();
    },
    initGoodsManagement() {
      this.$refs.GoodsManagement.getGoodsList();
    },
    initHeadSetting() {
      this.$refs.headSetting.pageInit();
    },
  },
  created() {
    if (this.$route.query.info) {
      let info = JSON.parse(this.$route.query.info);
      this.id = info.id;
      this.getStoreInfo(info);
    } else if (this.$route.query.id) {
      this.getStoreInfo({ store_id: this.$route.query.id });
    } else {
      return false;
    }
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.tabitem {
  margin-top: 10px;
  height: 100%;
}
/deep/.el-tabs {
  height: 100%;
  .el-tabs__content {
    height: calc(100% - 55px);
    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
