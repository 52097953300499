<template>
  <el-main>
    <el-form ref="form" :model="info" :rules="rules" label-width="120px">
      <el-form-item label="店员姓名：" prop="staff_name">
        <el-input v-model="info.staff_name"></el-input>
      </el-form-item>
      <el-form-item label="登录用户名：" prop="staff_account">
        <el-input v-model="info.staff_account"></el-input>
      </el-form-item>
      <el-form-item label="登录密码：" prop="staff_password">
        <el-input type="password" show-password v-model="info.staff_password"></el-input>
      </el-form-item>
      <el-form-item label="店员头像：">
        <ReadyUploadSource
          :showStyle="{
            width: '100px',
            height: '100px',
          }"
          :path="info.staff_avatar"
          @getSource="val => (info.staff_avatar = val.path)"
          @removeThis="() => (info.staff_avatar = '')"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="联系电话：" prop="staff_phone">
        <el-input v-model="info.staff_phone"></el-input>
      </el-form-item>
      <el-form-item label="是否启用：">
        <el-radio-group v-model="info.state">
          <el-radio :label="0">禁用</el-radio>
          <el-radio :label="1">启用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Preservation,
    ReadyUploadSource,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      info: {},
      rules: {
        staff_name: [{ required: true, message: '请输入店员姓名', trigger: 'blur' }],
        staff_account: [{ required: true, message: '请输入登录用户名：', trigger: 'blur' }],
        staff_password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
        staff_phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getIndfo();
  },
  methods: {
    // 保存配置
    preservation() {
      let that = this;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.store.StoreUsersedit, {
              id: this.info.id,
              staff_name: this.info.staff_name,
              staff_account: this.info.staff_account,
              staff_password: this.info.staff_password,
              staff_avatar: this.info.staff_avatar,
              staff_phone: this.info.staff_phone,
              state: this.info.state,
            })
            .then(res => {
              if (res.code == 0) {
                that.$message.success('保存设置成功');
              } else {
                that.$message.error(`${res.msg}`);
              }
            });
        }
      });
    },
    // 获取商户账号信息
    getIndfo() {
      let that = this;
      this.$axios
        .post(this.$api.store.StoreUsersinfo, {
          store_id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            that.info = res.result;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  .el-input {
    width: 500px;
  }
}
</style>
