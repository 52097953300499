<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-19 10:08:26
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-22 17:03:21
 * @FilePath: /yiyun_project/src/components/preservation.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="preservationBox" :style="{ width: addPage ? 'calc(100vw - 150px)' : 'calc(100vw - 285px)', right: addPage ? '0px' : '15px' }">
    <slot name="return"></slot>
    <template v-if="step.max">
      <el-button type="primary" v-if="step.now > 1" @click="$emit('preservation', 0)">上一步</el-button>
      <el-button type="primary" @click="$emit('preservation', 1)">{{ step.now < step.max ? '下一步' : '保存' }}</el-button>
    </template>
    <template v-if="activeName != null">
      <el-button @click="$emit('nextOr')">{{ activeName == 'first' ? '下一步' : '上一步' }}</el-button>
      <el-button v-show="activeName == 'second'" type="primary" @click="$emit('preservation')">保存</el-button>
    </template>
    <template v-if="isSave != null">
      <el-button type="primary" @click="$emit('preservation')">{{ isSave ? '编辑' : '保存' }}</el-button>
    </template>
    <template v-if="isSave == null && activeName == null && !step.max">
      <el-button type="primary" @click="$emit('preservation')">{{ text }}</el-button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: () => {
        return {
          now: 1,
          max: 0,
        };
      },
    },
    activeName: {
      type: String,
      default: () => null,
    },
    isSave: {
      type: Boolean,
      default: () => null,
    },
    addPage: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '保存',
    },
  },
};
</script>

<style lang="less" scoped>
.preservationBox {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 0 8px 2px rgba(64, 158, 255, 0.2);
}
</style>
